@import'./constants/constants.scss';

html {
  font-size: 100%;
}

body {
  background-color: $backGroundApp;
}

.trash-icon {
  height: 1.25rem;
  cursor: pointer;
}

div.table-responsive {
  height: 80vh;
}